// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-holding-js": () => import("./../../../src/pages/indexHolding.js" /* webpackChunkName: "component---src-pages-index-holding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovation-nation-2018-js": () => import("./../../../src/pages/innovation-nation2018.js" /* webpackChunkName: "component---src-pages-innovation-nation-2018-js" */),
  "component---src-pages-linenmill-studios-js": () => import("./../../../src/pages/linenmill-studios.js" /* webpackChunkName: "component---src-pages-linenmill-studios-js" */),
  "component---src-pages-mipim-2018-js": () => import("./../../../src/pages/mipim2018.js" /* webpackChunkName: "component---src-pages-mipim-2018-js" */),
  "component---src-pages-mipim-2019-js": () => import("./../../../src/pages/mipim2019.js" /* webpackChunkName: "component---src-pages-mipim-2019-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-story-js": () => import("./../../../src/pages/news-story.js" /* webpackChunkName: "component---src-pages-news-story-js" */),
  "component---src-pages-nie-js": () => import("./../../../src/pages/nie.js" /* webpackChunkName: "component---src-pages-nie-js" */),
  "component---src-pages-outlook-js": () => import("./../../../src/pages/outlook.js" /* webpackChunkName: "component---src-pages-outlook-js" */),
  "component---src-pages-rorybest-js": () => import("./../../../src/pages/rorybest.js" /* webpackChunkName: "component---src-pages-rorybest-js" */),
  "component---src-pages-statsports-js": () => import("./../../../src/pages/statsports.js" /* webpackChunkName: "component---src-pages-statsports-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-news-story-js": () => import("./../../../src/templates/news-story.js" /* webpackChunkName: "component---src-templates-news-story-js" */)
}

